"use client";

import { useState } from "react";

export default function MesajFormu() {
  const [formInput, setFormInput] = useState("");
  const [sqlOutput, setSqlOutput] = useState("");

  const handleSubmit = () => {
    const sqlQuery = `SELECT LRF.*, LRF.ROWID FROM LANGUAGE_RESOURCE_FORMS LRF
WHERE LRF.NAME LIKE '%${formInput}%';

COMMIT;`;

    setSqlOutput(sqlQuery);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(sqlOutput);
  };

  const handleClear = () => {
    setFormInput("");
    setSqlOutput("");
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-800">Form Sorgulama</h2>
        <button
          onClick={handleClear}
          className="text-sm text-gray-600 hover:text-gray-800 flex items-center gap-1"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
          Temizle
        </button>
      </div>

      <div className="grid gap-6 bg-white rounded-lg p-6 shadow-sm">
        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Form Adı
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </span>
            <input
              type="text"
              value={formInput}
              onChange={(e) => setFormInput(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
              placeholder="Form adını giriniz..."
            />
          </div>
        </div>

        <div className="flex gap-3">
          <button
            onClick={handleSubmit}
            className="flex-1 bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2 font-medium"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
            Sorgula
          </button>
        </div>

        {sqlOutput && (
          <div className="mt-4 bg-gray-50 rounded-lg border border-gray-200 overflow-hidden">
            <div className="flex justify-between items-center px-4 py-2 bg-gray-100 border-b border-gray-200">
              <label className="text-sm font-medium text-gray-700">
                SQL Sorgusu
              </label>
              <button
                onClick={handleCopy}
                className="flex items-center gap-1 px-3 py-1 text-sm bg-white text-gray-600 rounded-md hover:bg-gray-50 transition-colors border border-gray-300"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                Kopyala
              </button>
            </div>
            <pre className="p-4 text-sm text-gray-700 whitespace-pre overflow-auto max-h-96">
              {sqlOutput}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
} 