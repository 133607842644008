"use client";

import { useState, useEffect, useRef } from "react";

interface MenuItem {
  id: string;
  name: string;
}

interface RiskStatus {
  id: string;
  name: string;
}

export default function Yetki() {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const menuItems: MenuItem[] = [
    { id: "1", name: "Hasta Kabul" },
    { id: "2", name: "Hasta Yatış" },
    { id: "3", name: "Poliklinik" },
    { id: "4", name: "Kasa" },
    { id: "5", name: "Eczane" },
    { id: "6", name: "Laboratuvar" },
    { id: "7", name: "Görüntüleme" },
    { id: "8", name: "Ameliyathane" },
    { id: "9", name: "Doğumhane" },
    { id: "10", name: "Acil Servis" },
    { id: "11", name: "Yoğun Bakım" },
    { id: "12", name: "Diyaliz" },
    { id: "13", name: "Fizik Tedavi" },
    { id: "14", name: "Kan Bankası" },
    { id: "15", name: "Depo" },
    { id: "16", name: "Satın Alma" },
    { id: "17", name: "Faturalama" },
    { id: "18", name: "Muhasebe" },
    { id: "19", name: "İnsan Kaynakları" },
    { id: "20", name: "Kalite" },
    { id: "21", name: "Yönetim" },
    { id: "22", name: "Bilgi İşlem" },
    { id: "99", name: "Radyoloji İşlemleri" },
    { id: "29", name: "YOĞUN BAKIM SİSTEMİ" },
    { id: "100", name: "Yoğun Bakım İşlemleri" },
    { id: "-1", name: "SIK KULLANILANLAR" },
    { id: "0", name: "HIZLI MENÜ" },
    { id: "258", name: "Tıbbi Kart" },
    { id: "72", name: "Stok İstekleri" },
    { id: "71", name: "Demirbaş" },
    { id: "67", name: "Toplu Tahsilat" },
    { id: "62", name: "Detaylı İşlem Sorgulama" },
    { id: "60", name: "Doktor İşlemleri" },
    { id: "61", name: "Tahsilat" },
    { id: "50", name: "Hasta Geliş Dosyası" },
    { id: "51", name: "Hasta Kimlik Dosyası" },
    { id: "52", name: "Ana Ekran" },
    { id: "53", name: "Stok Yönetimi" },
    { id: "55", name: "Hasta İndirimi" },
    { id: "56", name: "Toplu Faturalama" },
    { id: "57", name: "İcmal İşlemleri" },
    { id: "58", name: "Laboratuar Sonuçları" },
    { id: "59", name: "Raporlar" },
    { id: "63", name: "Hakedişler" },
    { id: "64", name: "Kasa Hareketleri" },
    { id: "65", name: "Haberleşme" },
    { id: "66", name: "Stok Alım İşlemleri" },
    { id: "68", name: "Tablo Zorunlu Alanları Ekranı" },
    { id: "69", name: "Kasa İşlemleri" },
    { id: "73", name: "Diş Tedavi Ekranı" },
    { id: "75", name: "Yatak Takip Sistemi" },
    { id: "263", name: "Yatak Rezervasyon" },
    { id: "264", name: "Çalışma Çizelgesi" },
    { id: "265", name: "Tıbbi Atık Yönetimi" },
    { id: "267", name: "Majistral İlaç Karışım Tanımları" },
    { id: "102", name: "Hazır Metinler" },
    { id: "266", name: "DÖF Tanımları" }
  ];

  const riskStatusItems: RiskStatus[] = [
    { id: "1", name: "Düşük Risk" },
    { id: "2", name: "Orta Risk" },
    { id: "3", name: "Yüksek Risk" }
  ];

  const [yetkiForm, setYetkiForm] = useState({
    yetkiName: "",
    yetkiExplanation: "",
    mId: "",
    riskStatus: "",
    version: ""
  });
  const [sqlOutput, setSqlOutput] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const formatVersion = (version: string) => {
    const parts = version.split('.');
    while (parts.length < 4) {
      parts.push('00');
    }
    return parts.map((part, index) => {
      if (index >= 2) {
        return part.padStart(4, '0');
      }
      return part.padStart(2, '0');
    }).join('.');
  };

  const handleSubmit = () => {
    const defaultVersion = "4.21";
    const version = yetkiForm.version || defaultVersion;
    const formattedVersion = formatVersion(version);
    
    const sqlQuery = `INSERT INTO AUTHORITY_DEFINITIONS
(NAME, EXPLANATION, M_ID, RISK_STATUS, VERSION)
VALUES
('${yetkiForm.yetkiName}',
'${yetkiForm.yetkiExplanation}',
${yetkiForm.mId},
'${yetkiForm.riskStatus}',
'${formattedVersion}');

COMMIT;`;

    setSqlOutput(sqlQuery);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(sqlOutput);
  };

  const handleClear = () => {
    setYetkiForm({
      yetkiName: "",
      yetkiExplanation: "",
      mId: "",
      riskStatus: "",
      version: ""
    });
    setSqlOutput("");
    setSearchTerm("");
  };

  const filteredMenuItems = menuItems.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.id.includes(searchTerm)
  );

  const handleMenuItemSelect = (item: MenuItem) => {
    setYetkiForm({...yetkiForm, mId: item.id});
    setSearchTerm(item.name);
    setIsDropdownOpen(false);
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-800">Yetki Tanımlama</h2>
        <button
          onClick={handleClear}
          className="text-sm text-gray-600 hover:text-gray-800 flex items-center gap-1"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
          Temizle
        </button>
      </div>

      <div className="grid gap-6 bg-white rounded-lg p-6 shadow-sm">
        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Yetki Adı
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
              </svg>
            </span>
            <input
              type="text"
              value={yetkiForm.yetkiName}
              onChange={(e) => setYetkiForm({...yetkiForm, yetkiName: e.target.value})}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
              placeholder="Yetki adını giriniz..."
            />
          </div>
        </div>

        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Yetki Açıklaması
          </label>
          <div className="relative">
            <span className="absolute top-3 left-3 text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7" />
              </svg>
            </span>
            <textarea
              value={yetkiForm.yetkiExplanation}
              onChange={(e) => setYetkiForm({...yetkiForm, yetkiExplanation: e.target.value})}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors resize-none h-32"
              placeholder="Yetki açıklamasını giriniz..."
            />
          </div>
        </div>

        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            M_ID
          </label>
          <div className="flex space-x-2">
            <div className="relative flex-1" ref={dropdownRef}>
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              </span>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setIsDropdownOpen(true);
                }}
                onFocus={() => setIsDropdownOpen(true)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                placeholder="Form adını arayın veya seçin"
              />
              {isDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-auto">
                  {filteredMenuItems.map((item) => (
                    <button
                      key={item.id}
                      onClick={() => handleMenuItemSelect(item)}
                      className="w-full px-4 py-2 text-left hover:bg-gray-100 flex justify-between items-center"
                    >
                      <span>{item.name}</span>
                      <span className="text-gray-500 text-sm">ID: {item.id}</span>
                    </button>
                  ))}
                </div>
              )}
            </div>
            <input
              type="text"
              value={yetkiForm.mId}
              onChange={(e) => setYetkiForm({...yetkiForm, mId: e.target.value})}
              className="w-32 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
              placeholder="M_ID"
            />
          </div>
        </div>

        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Risk Durumu
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </span>
            <select
              value={yetkiForm.riskStatus}
              onChange={(e) => setYetkiForm({...yetkiForm, riskStatus: e.target.value})}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors appearance-none"
            >
              <option value="">Seçiniz...</option>
              {riskStatusItems.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            <span className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>

        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Versiyon
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
              </svg>
            </span>
            <input
              type="text"
              value={yetkiForm.version}
              onChange={(e) => setYetkiForm({...yetkiForm, version: e.target.value})}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
              placeholder="4.21"
            />
          </div>
        </div>

        <div className="flex gap-3">
          <button
            onClick={handleSubmit}
            className="flex-1 bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2 font-medium"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
            SQL Oluştur
          </button>
        </div>

        {sqlOutput && (
          <div className="mt-4 bg-gray-50 rounded-lg border border-gray-200 overflow-hidden">
            <div className="flex justify-between items-center px-4 py-2 bg-gray-100 border-b border-gray-200">
              <label className="text-sm font-medium text-gray-700">
                SQL Sorgusu
              </label>
              <button
                onClick={handleCopy}
                className="flex items-center gap-1 px-3 py-1 text-sm bg-white text-gray-600 rounded-md hover:bg-gray-50 transition-colors border border-gray-300"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                Kopyala
              </button>
            </div>
            <pre className="p-4 text-sm text-gray-700 whitespace-pre overflow-auto max-h-96">
              {sqlOutput}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
} 