import React, { useEffect } from 'react';
import './assets/home.css';
import BgParticles from '../../../components/Particles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChain,
  faPlusCircle,
  faBook,
  faEnvelope,
  faDiagramProject,
  faCode,
  faServer,
  faMobile,
  faPhone
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="modern-app">
      <div className="particles-container">
        <BgParticles />
      </div>
      
      <div className="hero-section">
        <div className="hero-content" data-aos="fade-up">
          <div className="profile-info">
            <img
              onClick={() => navigate('/Login')}
              className="profile-picture"
              src="https://avatars.githubusercontent.com/u/76844052?v=4"
              alt="Alper Özmen"
            />
            <div className="hero-text">
              <h1>Alper Özmen</h1>
              <h2>Full Stack Developer</h2>
              <div className="social-links">
                <a href="tel:+905531789876" className="social-link">
                  <FontAwesomeIcon icon={faPhone} />
                </a>
                <a href="mailto:alp3rozmen@gmail.com" className="social-link">
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
                <a href="https://github.com/alp3rozmen" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
                <a href="https://www.linkedin.com/in/alper-%C3%B6-360198213/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="modern-nav" data-aos="fade-down">
        <ul>
          <li><a href="#about"><FontAwesomeIcon icon={faBook} /> Hakkımda</a></li>
          <li><a href="#projects"><FontAwesomeIcon icon={faDiagramProject} /> Projelerim</a></li>
          <li><a href="#services"><FontAwesomeIcon icon={faPlusCircle} /> Hizmetlerim</a></li>
          <li><a href="#portfolio"><FontAwesomeIcon icon={faChain} /> Referanslarım</a></li>
        </ul>
      </nav>

      <section id="about" className="section" data-aos="fade-up">
        <h2 className="section-title">Hakkımda</h2>
        <div className="about-content">
          <p>
            Merhaba, ben Alper. Yazılım geliştirme konusundaki tutkumla ve deneyimimle ön plana çıkan biriyim. 
            2 yıl süresince yazılım projeleri üzerinde çalışarak kendimi sürekli geliştirdim.
            Yeni teknolojileri öğrenmek ve projelerde uygulamak beni heyecanlandırıyor.
          </p>
          <div className="skills-grid">
            <div className="skill-card" data-aos="zoom-in">
              <FontAwesomeIcon icon={faCode} />
              <h3>Frontend</h3>
              <p>React, React Native</p>
            </div>
            <div className="skill-card" data-aos="zoom-in" data-aos-delay="100">
              <FontAwesomeIcon icon={faServer} />
              <h3>Backend</h3>
              <p>CodeIgniter, Laravel</p>
            </div>
            <div className="skill-card" data-aos="zoom-in" data-aos-delay="200">
              <FontAwesomeIcon icon={faMobile} />
              <h3>Mobile</h3>
              <p>Android (Java), React Native</p>
            </div>
          </div>
        </div>
      </section>

      <section id="projects" className="section" data-aos="fade-up">
        <h2 className="section-title">Projelerim</h2>
        <div className="projects-grid">
          <div className="project-card" data-aos="fade-up">
            <h3>Kahve Falı Uygulaması</h3>
            <p>Kullanıcılara kahve falı bakma deneyimi sunan bir Android uygulaması. Firebase kullanarak kullanıcıların fal bilgilerini depoladım.</p>
            <div className="project-tags">
              <span>Android</span>
              <span>Firebase</span>
              <span>Java</span>
            </div>
          </div>
          <div className="project-card" data-aos="fade-up" data-aos-delay="100">
            <h3>Döviz Kurları Uygulaması</h3>
            <p>Canlı döviz kurlarını takip etmek için bir Android uygulaması. Firebase kullanarak güncel döviz verilerini alıp gösterdim.</p>
            <div className="project-tags">
              <span>Android</span>
              <span>Firebase</span>
              <span>API Integration</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
