
import { useState } from "react";
import BirimTest from "./components/BirimTest";
import MesajEkleme from "./components/MesajEkleme";
import MesajFormu from "./components/MesajFormu";
import SistemParametresi from "./components/SistemParametresi";
import Yetki from "./components/Yetki";
import YetkiFormu from "./components/YetkiFormu";
import "./page.css";

export default function BizHelper() {
  const [activeTab, setActiveTab] = useState("BirimTest");
  const [activeSubTab, setActiveSubTab] = useState("");

  const renderContent = () => {
    switch(activeTab) {
      case "BirimTest":
        return <BirimTest />;
      case "Mesaj İşlemleri":
        if (activeSubTab === "Mesaj Ekleme") return <MesajEkleme />;
        if (activeSubTab === "Mesaj Formu") return <MesajFormu />;
        return <MesajEkleme />;
      case "Sistem Parametresi":
        return <SistemParametresi />;
      case "Yetki":
        if (activeSubTab === "Yetki") return <Yetki />;
        if (activeSubTab === "Yetki Formu") return <YetkiFormu />;
        return <Yetki />;
      default:
        return null;
    }
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    if (tab === "Mesaj İşlemleri") {
      setActiveSubTab("Mesaj Ekleme");
    } else if (tab === "Yetki") {
      setActiveSubTab("Yetki");
    } else {
      setActiveSubTab("");
    }
  };

  const handleSubTabClick = (subTab: string) => {
    setActiveSubTab(subTab);
  };

  return (
    <div className="flex min-h-screen bg-gray-50">
      {/* Sidebar */}
      <div className="w-64 bg-white shadow-lg fixed h-full">
        <div className="sticky top-0 z-10 bg-white border-b border-gray-200">
          <div className="p-4">
            <h1 className="text-xl font-bold text-gray-800">Kolaylıklar</h1>
          </div>
          <nav className="p-4 space-y-1">
            {[
              { id: "BirimTest", name: "Birim Test", icon: "🧪" },
              { id: "Mesaj İşlemleri", name: "Mesaj İşlemleri", icon: "✉️" },
              { id: "Sistem Parametresi", name: "Sistem Parametresi", icon: "⚙️" },
              { id: "Yetki", name: "Yetki", icon: "🔒" }
            ].map((tab) => (
              <button
                key={tab.id}
                onClick={() => handleTabClick(tab.id)}
                className={`w-full flex items-center gap-3 px-4 py-3 rounded-lg text-left font-medium text-sm transition-all ${
                  activeTab === tab.id
                    ? "bg-blue-50 text-blue-600 shadow-sm"
                    : "text-gray-600 hover:bg-gray-50"
                }`}
              >
                <span className="text-xl">{tab.icon}</span>
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 ml-64">
        <main className="p-8">
          {/* Alt Sekmeler */}
          {activeTab === "Mesaj İşlemleri" && (
            <div className="sticky top-0 z-10 mb-8 bg-white rounded-lg shadow-sm p-2">
              <nav className="flex space-x-2">
                {[
                  { id: "Mesaj Ekleme", name: "Mesaj Ekleme", icon: "📝" },
                  { id: "Mesaj Formu", name: "Mesaj Formu", icon: "📋" }
                ].map((subTab) => (
                  <button
                    key={subTab.id}
                    onClick={() => handleSubTabClick(subTab.id)}
                    className={`flex items-center gap-2 px-4 py-2 rounded-md font-medium text-sm transition-all ${
                      activeSubTab === subTab.id
                        ? "bg-blue-50 text-blue-600"
                        : "text-gray-600 hover:bg-gray-50"
                    }`}
                  >
                    <span>{subTab.icon}</span>
                    {subTab.name}
                  </button>
                ))}
              </nav>
            </div>
          )}

          {activeTab === "Yetki" && (
            <div className="sticky top-0 z-10 mb-8 bg-white rounded-lg shadow-sm p-2">
              <nav className="flex space-x-2">
                {[
                  { id: "Yetki", name: "Yetki", icon: "🔑" },
                  { id: "Yetki Formu", name: "Yetki Formu", icon: "📄" }
                ].map((subTab) => (
                  <button
                    key={subTab.id}
                    onClick={() => handleSubTabClick(subTab.id)}
                    className={`flex items-center gap-2 px-4 py-2 rounded-md font-medium text-sm transition-all ${
                      activeSubTab === subTab.id
                        ? "bg-blue-50 text-blue-600"
                        : "text-gray-600 hover:bg-gray-50"
                    }`}
                  >
                    <span>{subTab.icon}</span>
                    {subTab.name}
                  </button>
                ))}
              </nav>
            </div>
          )}

          {/* İçerik */}
          <div className="bg-white rounded-lg shadow-sm">
            {renderContent()}
          </div>
        </main>
      </div>
    </div>
  );
}
