import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faAndroid, faReact } from '@fortawesome/free-brands-svg-icons';
import '../styles/private-layout.css';

function Projects() {
  const projects = [
    {
      title: 'Kahve Falı Uygulaması',
      description: 'Android tabanlı kahve falı uygulaması. Firebase entegrasyonu ile kullanıcı verileri yönetimi.',
      tech: ['Android', 'Java', 'Firebase'],
      icon: faAndroid
    },
    {
      title: 'Döviz Kurları Takip',
      description: 'Anlık döviz kuru takibi yapabilen mobil uygulama. API entegrasyonu ile güncel veriler.',
      tech: ['Android', 'Java', 'REST API'],
      icon: faAndroid
    },
    {
      title: 'Kişisel Web Sitesi',
      description: 'Modern ve responsive tasarıma sahip kişisel portfolyo web sitesi.',
      tech: ['React', 'TypeScript', 'CSS'],
      icon: faReact
    },
    {
      title: 'GitHub Projeleri',
      description: 'Diğer tüm açık kaynak projelerime GitHub üzerinden ulaşabilirsiniz.',
      tech: ['Various'],
      icon: faGithub,
      link: 'https://github.com/alp3rozmen'
    }
  ];

  return (
    <div className="projects">
      <div className="projects-header">
        <h1 className="projects-title">Projelerim</h1>
        <p className="text-gray-600">Geliştirdiğim projeler ve detayları</p>
      </div>
      
      <div className="projects-grid">
        {projects.map((project, index) => (
          <div className="project-card" key={index}>
            <FontAwesomeIcon 
              icon={project.icon} 
              className="text-4xl mb-4 text-blue-500"
            />
            <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
            <p className="text-gray-600 mb-4">{project.description}</p>
            <div className="flex flex-wrap gap-2">
              {project.tech.map((tech, techIndex) => (
                <span 
                  key={techIndex}
                  className="px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm"
                >
                  {tech}
                </span>
              ))}
            </div>
            {project.link && (
              <a 
                href={project.link}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 inline-block text-blue-500 hover:text-blue-600"
              >
                Projeyi Görüntüle →
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;