import { useState } from "react";

export default function BirimTest() {
  const [testInput, setTestInput] = useState("");
  const [testOutput, setTestOutput] = useState("");

  const handleTest = () => {
    const lines = testInput.split('\n');
    let result = '';

    lines.forEach(line => {
      let trimmedLine = line.trim().replace(/^\d+\)\s*/, '').trim();
      if (!trimmedLine) return;

      trimmedLine = trimmedLine.replace(/["]/g, '');

      if (trimmedLine.includes('.exe açılır')) {
        result += `Ekranın Açılması\n`;
      } else if (trimmedLine.includes('menüsü açılır')) {
        result += `Ekranın Açılması\n`;
      } else if (trimmedLine.endsWith('açılır')) {
        result += `Ekranın Açılması\n`;
      } else if (trimmedLine.includes('basılır')) {
        result += `İlgili Alana Basılması\n`;
      } else if (trimmedLine.includes('seçilir')) {
        result += `İlgili Seçim Yapılması\n`;
      } else if (trimmedLine.includes('görüntülenir')) {
        result += `İlgili Alanın Görüntülenmesi\n`;
      } else if (trimmedLine.includes('girilir') || trimmedLine.includes('girilir.')) {
        result += `Veri Girilmesi\n`;
      } else if (trimmedLine.includes('açılır') || trimmedLine.includes('açılır.')) {
        result += `Ekranın Açılması\n`;
      } else if (trimmedLine.includes('butonu tıklanır') || trimmedLine.includes('butonu tıklanır.')) {
        result += `İlgili Buttona Tıklanması\n`;
      } else if (trimmedLine.includes('sağ tıklanır') || trimmedLine.includes('sağ tıklanır.')) {
        result += `İlgili Alana Sağ Tıklanması\n`;
      } else if (trimmedLine.includes('doldurulur') || trimmedLine.includes('doldurulur.')) {
        result += `İlgili Alan Doldurulması\n`;
      } else if (trimmedLine.includes('seçimi yapılır') || trimmedLine.includes('seçimi yapılır.')) {
        result += `İlgili Seçim Yapılması\n`;
      } else if (trimmedLine.includes('yapılır') || trimmedLine.includes('yapılır.')) {
        result += `İlgili İşlem Yapılması\n`;
      } else if (trimmedLine.includes('kullanılabilir') || trimmedLine.includes('kullanılabilir.')) {
        result += `İlgili İşlem Kullanılması\n`;
      } else if (trimmedLine.includes('tıklanır') || trimmedLine.includes('tıklanır.')) {
        result += `İlgili Alana Tıklanması\n`;
      }
    });

    setTestOutput(result.trim());
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const handleClear = () => {
    setTestInput("");
    setTestOutput("");
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-800">Test Yönetimi</h2>
        <div className="flex items-center gap-2">
          <span className="text-sm text-gray-500">Kolaylıklar</span>
          <button
            onClick={handleClear}
            className="text-sm text-gray-600 hover:text-gray-800 flex items-center gap-1"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
            Temizle
          </button>
        </div>
      </div>

      <div className="grid gap-6 bg-white rounded-lg p-6 shadow-sm">
        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Test Adımları
          </label>
          <div className="relative">
            <span className="absolute top-3 left-3 text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
              </svg>
            </span>
            <textarea
              value={testInput}
              onChange={(e) => setTestInput(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors resize-none h-96"
              placeholder="Test adımlarını giriniz..."
            />
          </div>
        </div>

        <div className="flex gap-3">
          <button
            onClick={handleTest}
            className="flex-1 bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2 font-medium"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Testi Çalıştır
          </button>
        </div>

        {testOutput && (
          <div className="mt-4 bg-gray-50 rounded-lg border border-gray-200 overflow-hidden">
            <div className="flex justify-between items-center px-4 py-2 bg-gray-100 border-b border-gray-200">
              <label className="text-sm font-medium text-gray-700">
                Test Sonucu
              </label>
              <button
                onClick={() => handleCopy(testOutput)}
                className="flex items-center gap-1 px-3 py-1 text-sm bg-white text-gray-600 rounded-md hover:bg-gray-50 transition-colors border border-gray-300"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                Tümünü Kopyala
              </button>
            </div>
            <div className="p-4 space-y-2">
              {testOutput.split('\n').map((line, index) => (
                <div key={index} className="flex items-center justify-between group">
                  <span className="text-sm text-gray-700">{line}</span>
                  <button
                    onClick={() => handleCopy(line)}
                    className="opacity-0 group-hover:opacity-100 flex items-center gap-1 px-2 py-1 text-xs bg-white text-gray-600 rounded-md hover:bg-gray-50 transition-all border border-gray-300"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                    </svg>
                    Kopyala
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 