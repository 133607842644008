"use client";

import { useState, useEffect, useRef } from "react";

interface FormItem {
  id: string;
  name: string;
}

export default function MesajEkleme() {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const formItems: FormItem[] = [
    { id: "1", name: "frmMain" },
    { id: "2", name: "frmPatIdentity" },
    { id: "3", name: "frmGSSInfos" },
    { id: "4", name: "frmGSSProcess" },
    { id: "5", name: "frmObjectsbM" },
    { id: "6", name: "frmDBConsole" },
    { id: "7", name: "frmNewSearchPatient" },
    { id: "8", name: "frmSecurity" },
    { id: "9", name: "frmPatReception" },
    { id: "10", name: "frmPatMandFld" },
    { id: "11", name: "frmMaterials_def" },
    { id: "12", name: "frmPriceLists" },
    { id: "14", name: "frmPatientFile" },
    { id: "15", name: "frmAssociationList" },
    { id: "16", name: "frmEntrance" },
    { id: "17", name: "frmRevenue" },
    { id: "18", name: "frmDoctorProcess" },
    { id: "19", name: "frmTillReport" },
    { id: "20", name: "frmPassword" },
    { id: "21", name: "frmInvoice" },
    { id: "22", name: "frmInvoiceGroup" },
    { id: "23", name: "frmHosBed" },
    { id: "25", name: "frmDetailQuery" },
    { id: "26", name: "frmMaterialInvoice" },
    { id: "27", name: "frmPatientProcessDetail" },
    { id: "28", name: "frmDiscount" },
    { id: "30", name: "frmStockMovementVoucher" },
    { id: "31", name: "frmLabInstruments" },
    { id: "32", name: "frmLabTestColors" },
    { id: "33", name: "frmLabTestDefines" },
    { id: "34", name: "frmLabTestGroups" },
    { id: "35", name: "frmDoctors" },
    { id: "36", name: "frmPatFileModify" },
    { id: "37", name: "frmDepartments" },
    { id: "38", name: "frmDoctorDeserves" },
    { id: "39", name: "frmLabTestResults" },
    { id: "40", name: "frmRequestsForPat" },
    { id: "41", name: "frmRequestDesign" },
    { id: "42", name: "frmInvoiceInfo" },
    { id: "43", name: "frmStockQuery" },
    { id: "44", name: "frmStockRequests" },
    { id: "45", name: "frmPackageDefinition" },
    { id: "46", name: "frmWareHouseStocks" },
    { id: "47", name: "frmPackageDetailModify" },
    { id: "48", name: "frmLabBacteriaAntibiotic" },
    { id: "49", name: "frmAccountProcess" },
    { id: "50", name: "frmDiagnosis" },
    { id: "51", name: "frmModListOnPatFile" },
    { id: "52", name: "frmLabDynamicTestDefines" },
    { id: "53", name: "frmAutoRevenue" },
    { id: "54", name: "frmAssociationDef" },
    { id: "55", name: "frmUserControls" },
    { id: "56", name: "frmAppointmentProcess" },
    { id: "57", name: "frmUpdatebiz" },
    { id: "58", name: "frmPatientRequest" },
    { id: "59", name: "frmCurrentAccounts" },
    { id: "60", name: "frmAppProcessDetail" },
    { id: "61", name: "frmPatMakeDiscount" },
    { id: "62", name: "frmRelPatRec" },
    { id: "63", name: "frmPopupMsg" },
    { id: "64", name: "frmProform" },
    { id: "65", name: "frmExpenditureCentres" },
    { id: "66", name: "frmServiceProcess" },
    { id: "67", name: "frmNote" },
    { id: "68", name: "frmFileTransfer" },
    { id: "69", name: "frmTempServiceListValues" },
    { id: "70", name: "frmAppointmentQuery" },
    { id: "71", name: "frmAppSettings" },
    { id: "72", name: "frmMaterialWareHouseDetail" },
    { id: "73", name: "frmPreparedText" },
    { id: "74", name: "frmFontStyles" },
    { id: "75", name: "frmLogoTransfer" },
    { id: "76", name: "frmInvoiceNumber" },
    { id: "77", name: "frmLabSampleBarcodePrint" },
    { id: "78", name: "frmPeriodPriceListChange" },
    { id: "79", name: "frmAssociationOffices" },
    { id: "80", name: "frmIdentState" },
    { id: "81", name: "frmLabCalculatedTestDefines" },
    { id: "82", name: "frmServiceCosts" },
    { id: "83", name: "frmDailyExchanges" },
    { id: "84", name: "frmLabAntecedentResults" },
    { id: "86", name: "frmPriceListPeriod" },
    { id: "87", name: "frmDoRevenueRemainder" },
    { id: "88", name: "frmDetailQueryParamList" },
    { id: "89", name: "frmEnumerationResult" },
    { id: "90", name: "frmDonors" },
    { id: "91", name: "frmLabReqStatusColors" },
    { id: "92", name: "frmRequestAll" },
    { id: "93", name: "frmDrillDown" },
    { id: "94", name: "frmAssociation" },
    { id: "95", name: "frmMainAss" },
    { id: "96", name: "frmEpikriz" },
    { id: "97", name: "frmGenReport" },
    { id: "98", name: "frmMessenger" },
    { id: "99", name: "frmProcessRisk" },
    { id: "100", name: "frmGSSPatInfos" },
    { id: "101", name: "frmDiscountAuto" },
    { id: "102", name: "frmMsg" },
    { id: "103", name: "frmPrintCustom" },
    { id: "104", name: "frmInPatRequest" },
    { id: "105", name: "frmGSSQuery" },
    { id: "106", name: "frmAssContractDef" },
    { id: "107", name: "frmPatLabProcess" },
    { id: "108", name: "frmSecUserPrivModOptions" },
    { id: "110", name: "frmFindFirstPeriod" },
    { id: "111", name: "frmEmergency" },
    { id: "112", name: "frmInPatRequestList" },
    { id: "113", name: "frmPatFileMove" },
    { id: "114", name: "frmService" },
    { id: "115", name: "frmServiceCategory" },
    { id: "116", name: "frmDrDeserveCopy" },
    { id: "117", name: "frmPatFileControls" },
    { id: "118", name: "frmProcessControlList" },
    { id: "119", name: "frmDoRelatedMaterials" },
    { id: "120", name: "frmUserMessage" },
    { id: "121", name: "frmCusObjForm" },
    { id: "122", name: "frmDocument" },
    { id: "123", name: "frmUserGroup" },
    { id: "124", name: "frmHosInfoForm" },
    { id: "125", name: "frmStock" },
    { id: "126", name: "frmPatGenInfo" },
    { id: "127", name: "frmPatBlackList" },
    { id: "128", name: "frmAccOutIn" },
    { id: "129", name: "frmAccOutgoing" },
    { id: "130", name: "frmAdminQuery" },
    { id: "131", name: "frmPurchasing" },
    { id: "132", name: "frmStockWarning" },
    { id: "133", name: "frmStockSerialNum" },
    { id: "134", name: "frmPatientDialysis" },
    { id: "135", name: "frmPatientDialysisList" },
    { id: "136", name: "frmMessageTemplates" },
    { id: "137", name: "frmScheduledMessageJobs" },
    { id: "138", name: "frmEnumeration4Serial" },
    { id: "139", name: "frmBloodBank" },
    { id: "140", name: "frmStockConsignment" },
    { id: "141", name: "frmBloodBankDetail" },
    { id: "142", name: "frmBloodBankQuery" },
    { id: "143", name: "frmLogTableQuery" },
    { id: "144", name: "frmLogTableDef" },
    { id: "145", name: "frmSavedFiles" },
    { id: "147", name: "frmAttendant" },
    { id: "148", name: "frmLabPatSampleType" },
    { id: "149", name: "frmAssContDefList" },
    { id: "150", name: "frmAssContDef" },
    { id: "151", name: "frmAssContDefDet" },
    { id: "152", name: "frmGssFollowUp" },
    { id: "153", name: "frmbyzListCard" },
    { id: "154", name: "frmbyzListForm" },
    { id: "155", name: "frmbyzMedCard" },
    { id: "156", name: "frmbyzReport" },
    { id: "157", name: "frmbyzRequest" },
    { id: "158", name: "frmbyzVarSetting" },
    { id: "159", name: "frmCRMPlanAndDef" },
    { id: "161", name: "frmCRMAddNewProc" },
    { id: "162", name: "frmFirmsAggrement" },
    { id: "163", name: "frmCRMQuery" },
    { id: "164", name: "frmbyzMEDTable" },
    { id: "165", name: "frmAssContDefCopy" },
    { id: "166", name: "frmInfoMessage" },
    { id: "167", name: "frmPhysiotherapyPatient" },
    { id: "168", name: "frmPhysiotherapyPatientList" },
    { id: "169", name: "frmCRMWorkList" },
    { id: "170", name: "frmVersionHistory" },
    { id: "171", name: "frmToothDetail" },
    { id: "172", name: "frmVoucherSelect" },
    { id: "173", name: "frmITSProcess" },
    { id: "174", name: "frmPatComIgnoreList" },
    { id: "175", name: "frmCRMPatientSatisfaction" },
    { id: "176", name: "frmCRMAddSatisfaction" },
    { id: "177", name: "frmToothProsthesis" },
    { id: "178", name: "frmTIGProcess" },
    { id: "179", name: "frmMainMedCard" },
    { id: "180", name: "frmMsgQuery" },
    { id: "181", name: "frmCentralManage" },
    { id: "182", name: "frmKiosk" },
    { id: "183", name: "frmTillAccount" },
    { id: "184", name: "frmJobList" },
    { id: "185", name: "frmJobDescription" },
    { id: "186", name: "frmJobAssign" },
    { id: "187", name: "frmInventoryStock" },
    { id: "188", name: "frmInventoryQuery" },
    { id: "189", name: "frmRationProcess" },
    { id: "190", name: "frmStocksInPatFile" },
    { id: "296", name: "frmDyob" },
    { id: "297", name: "frmEInvoiceControl" },
    { id: "298", name: "frmEInvoiceSendType" },
    { id: "299", name: "frmEmbryoMovement" },
    { id: "300", name: "frmEmbryology" },
    { id: "301", name: "frmEntgDrugSearch" },
    { id: "302", name: "frmInPatPackage" },
    { id: "303", name: "frmInPatStockSendBack" },
    { id: "304", name: "frmInvPreReject" },
    { id: "305", name: "frmInventoryStockWarning" },
    { id: "306", name: "frmInvoiceProcessControlList" },
    { id: "307", name: "frmLabErrorCode" },
    { id: "308", name: "frmLabGeneticTest" },
    { id: "309", name: "frmLabQControlResult" },
    { id: "310", name: "frmLabTestResLOB" },
    { id: "311", name: "frmMainDesign" },
    { id: "312", name: "frmMaterialPrescription" },
    { id: "313", name: "frmMedCardPanic" },
    { id: "314", name: "frmMedCardTemplate" },
    { id: "315", name: "frmMixedDragTemplates" },
    { id: "316", name: "frmModuleSpecialQuery" },
    { id: "317", name: "frmOperationReports" },
    { id: "318", name: "frmOrderUnitConvertion" },
    { id: "319", name: "frmPacsLabResults" },
    { id: "320", name: "frmPatGenHealthInfo" },
    { id: "321", name: "frmPathologyQuery" },
    { id: "322", name: "frmPatientBedReservation" },
    { id: "323", name: "frmPatientBedReservationDetail" },
    { id: "324", name: "frmPatientNotice" },
    { id: "325", name: "frmPersonnelQuery" },
    { id: "326", name: "frmPhotoCam" },
    { id: "327", name: "frmPhysiotherapySeanceTemplate" },
    { id: "328", name: "frmPhysiotherapySettings" },
    { id: "329", name: "frmPregnancy" },
    { id: "330", name: "frmPrivPerson" },
    { id: "331", name: "frmProfitRatios" },
    { id: "332", name: "frmProviderFirms" },
    { id: "333", name: "frmQuaDocAnalysis" },
    { id: "334", name: "frmQuaDocFolderSettings" },
    { id: "335", name: "frmQuaDocTransactions" },
    { id: "336", name: "frmQueryParams" },
    { id: "337", name: "frmRationalAntibiotic" },
    { id: "338", name: "frmRequestAddInfo" },
    { id: "339", name: "frmResultCodeEditing" },
    { id: "340", name: "frmSecurityReport" },
    { id: "341", name: "frmSelectWinCert" },
    { id: "342", name: "frmSendMail" },
    { id: "343", name: "frmSentry" },
    { id: "344", name: "frmSpermiyogram" },
    { id: "345", name: "frmStockMixedDrag" },
    { id: "346", name: "frmStockRequestTemplates" },
    { id: "347", name: "frmTbInfertilite" },
    { id: "348", name: "frmTbKoh" },
    { id: "349", name: "frmTbStart" },
    { id: "350", name: "frmTeletip" },
    { id: "351", name: "frmTillProcessWithoutPat" },
    { id: "352", name: "frmTraining" },
    { id: "353", name: "frmTubeBaby" },
    { id: "354", name: "frmUserAlertParams" },
    { id: "355", name: "frmUserAlertProcess" },
    { id: "356", name: "frmUserNotifications" },
    { id: "357", name: "frmWebBrowser" },
    { id: "358", name: "frmWebUsers" },
    { id: "359", name: "frmGssErrorMessage" },
    { id: "360", name: "frmPrintedConsentForms" },
    { id: "361", name: "frmRadDoctorProcess" },
    { id: "362", name: "frmQuaDocEditWord" },
    { id: "363", name: "frmQuaDocEditExcel" },
    { id: "364", name: "frmQuaDocEditPanel" },
    { id: "365", name: "frmPatientCare" },
    { id: "366", name: "frmNurseCarePlans" },
    { id: "367", name: "frmLabOdsDefines" },
    { id: "368", name: "frmHID" },
    { id: "369", name: "frmLabQCDefinitions" },
    { id: "370", name: "frmUpdatePlan" },
    { id: "371", name: "frmMCCatheterMaterialTrace" },
    { id: "372", name: "frmMCAdverseImpactForm" },
    { id: "373", name: "frmMCAnesthesiaEvaluation" },
    { id: "374", name: "frmMCConstraintEvaluation" },
    { id: "375", name: "frmMCPrismScore" },
    { id: "376", name: "frmReaderGroups" },
    { id: "3854", name: "frmInvoiceStatusQuery" },
    { id: "3855", name: "dmOss" },
    { id: "3856", name: "frmMCApacheII" },
    { id: "3857", name: "frmSafeBirthControl" },
    { id: "3858", name: "frmMCDietitianEvaluation" },
    { id: "3859", name: "frmMCPartograph" },
    { id: "3860", name: "frmMCPelodScore" },
    { id: "3861", name: "frmMCStrongKidsNutritionAssessment" },
    { id: "3867", name: "frmQuaDocEditGeneral" },
    { id: "3868", name: "frmMCSapsII" },
    { id: "3869", name: "frmWarehouseDefinition" },
    { id: "3870", name: "frmAnnouncement" },
    { id: "3871", name: "frmLdapDefines" },
    { id: "3872", name: "frmArchiveFileControl" },
    { id: "3873", name: "frmMCSafeSurgeryControl" },
    { id: "3874", name: "frmMCGlasgowScale" },
    { id: "3875", name: "frmPatProcessMove" },
    { id: "3876", name: "frmPersonalTrainingDefinitions" },
    { id: "3877", name: "frmPersonelPermission" },
    { id: "3878", name: "frmDiscountApprovedUserDefine" },
    { id: "3879", name: "frmLanguageSupport" },
    { id: "3880", name: "frmAssDocumentTrace" },
    { id: "3881", name: "frmInsentiveCare" },
    { id: "3882", name: "frmProvisionUpdate" },
    { id: "3883", name: "frmBloodRequestForm" },
    { id: "3884", name: "frmMCBloodTransfer" },
    { id: "3885", name: "frmMCEdinburghDepScale" },
    { id: "3886", name: "frmObstetrik" },
    { id: "3887", name: "frmMCNewBornCRIB" },
    { id: "3888", name: "frmMCPatientMonitoring" },
    { id: "3889", name: "frmMCCriticalCaseFollowUpForm" },
    { id: "3890", name: "frmMCFallRiskItaki" },
    { id: "3891", name: "frmMCBloodSugarTracing" },
    { id: "3892", name: "frmKVKKApproval" },
    { id: "3893", name: "frmGssProcessConnection" },
    { id: "3894", name: "frmSterilizationProcess" },
    { id: "3895", name: "frmSterilizationDefinition" },
    { id: "3896", name: "TAllianzOss" },
    { id: "3897", name: "frmAssDocumentSettings" },
    { id: "3898", name: "frmSurgeryPackageChecklist" },
    { id: "3899", name: "frmSurgeryPackageCheck" },
    { id: "3900", name: "frmPatPriceIncrement" },
    { id: "3901", name: "frmAssContCopy" },
    { id: "3902", name: "frmBizMonSettings" },
    { id: "3903", name: "frmLabWebUsersDefines" },
    { id: "3904", name: "frmMaterialInvoiceITS" },
    { id: "3905", name: "frmQuaDocTrackingSystem" },
    { id: "3906", name: "frmQuaDocTrcSysAdd" },
    { id: "3907", name: "frmLbysList" },
    { id: "3908", name: "frmCovid19LbysEntg" },
    { id: "3909", name: "frmAccessedObjectLogs" },
    { id: "3910", name: "frmPercentileCurve" },
    { id: "3911", name: "frmESignReport" },
    { id: "3912", name: "frmIsolationDefinitions" },
    { id: "3913", name: "frmIsolationControl" },
    { id: "3914", name: "frmMCPatientIsolation" },
    { id: "3915", name: "frmMCRevisedTraumaScore" },
    { id: "3916", name: "frmNurseProcessNew" },
    { id: "3917", name: "frmPatientProcessFastInsertPrice" },
    { id: "3918", name: "frmWorkScheduler" },
    { id: "3919", name: "frmWorkShiftEntry" },
    { id: "3920", name: "frmMedicalWasteManagement" },
    { id: "3921", name: "frmMedicalWasteDef" },
    { id: "3922", name: "frmDrugDetailEdit" },
    { id: "3923", name: "frmDiabetesTracking" },
    { id: "3924", name: "frmDofDefination" },
    { id: "3925", name: "frmToothServiceDetailDef" },
    { id: "3926", name: "frmDyob" },
    { id: "3927", name: "frmBoneMarrowProcess" },
    { id: "3928", name: "frmDofFollowList" },
    { id: "3929", name: "frmPatientProcessFastInsert" },
    { id: "3930", name: "frmScreenPermissions" },
    { id: "3931", name: "frmMCSofaScoreForm" },
    { id: "3932", name: "frmOpenImage" },
    { id: "191", name: "frmPatProcessCompare" },
    { id: "192", name: "frmDrugPrescription" },
    { id: "193", name: "frmActiveSubstance" },
    { id: "194", name: "frmWorkingList" },
    { id: "195", name: "frmJobFollowUpQuery" },
    { id: "196", name: "frmInvPreControls" },
    { id: "197", name: "frmMaintenanceDescription" },
    { id: "198", name: "frmLabQuery" },
    { id: "199", name: "frmDrugApprove" },
    { id: "200", name: "frmCRMReviews" },
    { id: "201", name: "frmGenOutOfWork" },
    { id: "202", name: "frmReadFromExcel" },
    { id: "203", name: "frmPollDescriptions" },
    { id: "204", name: "frmQuickAddCrmPoll" },
    { id: "205", name: "frmPatientPollAnswers" },
    { id: "206", name: "frmBedReservation" },
    { id: "207", name: "frmGSSShipping" },
    { id: "208", name: "frmMaterialFactorInteractions" },
    { id: "211", name: "frmLangResEdit" },
    { id: "212", name: "frmDrawImage" },
    { id: "213", name: "frmDrawImgMsg" },
    { id: "214", name: "frmExportPatFiles" },
    { id: "215", name: "frmPatGenMedInfos" },
    { id: "216", name: "frmNurseProcess" },
    { id: "217", name: "frmEInvoice" },
    { id: "218", name: "dmMain" },
    { id: "219", name: "dmGeneral" },
    { id: "220", name: "dmProcess" },
    { id: "221", name: "dmGSS" },
    { id: "222", name: "dmSecurity" },
    { id: "223", name: "dmAppoinment" },
    { id: "229", name: "services_def_frm" },
    { id: "237", name: "CommonConf" },
    { id: "239", name: "frmInPatReqOpTeams" },
    { id: "240", name: "frmBloodRequest" },
    { id: "241", name: "frmRequestPermission" },
    { id: "242", name: "frmSnetImmQuery" },
    { id: "243", name: "frmPatAcceptInfo" },
    { id: "244", name: "frmFastServiceAdd" },
    { id: "246", name: "frmPathologyProcess" },
    { id: "247", name: "frmPathologyLabChoose" },
    { id: "248", name: "frmPathologySpecialPaintins" },
    { id: "249", name: "frmPatSeansArrange" },
    { id: "250", name: "frmIsparkSendCar" },
    { id: "251", name: "frmOpticalPrescription" },
    { id: "252", name: "frmWarehouseStockMinMax" },
    { id: "253", name: "frmStockReqOrder" },
    { id: "254", name: "frmOrderStockSearch" },
    { id: "255", name: "frmStockReqEHUApprove" },
    { id: "256", name: "frmOrderVerbalReq" },
    { id: "257", name: "frmStockList" },
    { id: "258", name: "frmStockSerialMovControl" },
    { id: "259", name: "frmProtocolBook" },
    { id: "260", name: "frmPhysiotherapyPlans" },
    { id: "261", name: "frmGssDocumentSend" },
    { id: "262", name: "frmDrugIntegration" },
    { id: "263", name: "frmClinicIndicator" },
    { id: "264", name: "dmSNET" },
    { id: "265", name: "frmRationSupply" },
    { id: "266", name: "frmArchivePage" },
    { id: "267", name: "frmArchiveSettings" },
    { id: "268", name: "frmAddArchiveFile" },
    { id: "269", name: "frmArchiveFileContentControl" },
    { id: "270", name: "frmRationRequest" },
    { id: "271", name: "frmQuaDocument" },
    { id: "272", name: "frmQuaDocProcess" },
    { id: "273", name: "frmQuaDocManageProcess" },
    { id: "274", name: "frmACCConnection" },
    { id: "275", name: "frmAboutbM" },
    { id: "276", name: "frmAccCodes" },
    { id: "277", name: "frmAddKohDetail" },
    { id: "278", name: "frmAddress" },
    { id: "279", name: "frmAgentDiag" },
    { id: "280", name: "frmAllNotification" },
    { id: "281", name: "frmAllTransactionHistory" },
    { id: "282", name: "frmArchiveDefinition" },
    { id: "283", name: "frmArchiveProcess" },
    { id: "284", name: "frmAssFileAttachment" },
    { id: "285", name: "frmAssPatients" },
    { id: "286", name: "frmAssociationInvoiceForms" },
    { id: "287", name: "frmBarcodeReadControl" },
    { id: "288", name: "frmBedQueue" },
    { id: "289", name: "frmByzInput" },
    { id: "290", name: "frmCRMQuickAdd" },
    { id: "291", name: "frmContagionSendAndList" },
    { id: "292", name: "frmDiagAgents" },
    { id: "293", name: "frmDoctorRevenues" },
    { id: "294", name: "frmDrugPrescriptionColor" },
    { id: "295", name: "frmDrugPrescriptionTemplate" }
  ];

  const [messageName, setMessageName] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [formId, setFormId] = useState("");
  const [formSearchTerm, setFormSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sqlOutput, setSqlOutput] = useState("");
  const [error, setError] = useState("");

  const filteredFormItems = formItems.filter(item =>
    item.name.toLowerCase().includes(formSearchTerm.toLowerCase()) ||
    item.id.includes(formSearchTerm)
  );

  const handleFormSelect = (item: FormItem) => {
    setFormId(item.id);
    setFormSearchTerm(item.name);
    setIsDropdownOpen(false);
  };

  const handleSubmit = () => {
    if (!messageName.toLowerCase().startsWith("msg")) {
      setError("Mesaj adı 'msg' ile başlamalıdır!");
      return;
    }

    setError("");
    const sqlQuery = `INSERT INTO LANGUAGE_RESOURCE_MESSAGES (NAME, VALUE, LRF_ID) VALUES ('${messageName}', '${messageContent}', '${formId}');\nCOMMIT;`;
    setSqlOutput(sqlQuery);
  };

  const handleMessageNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMessageName(value);
    if (value && !value.toLowerCase().startsWith("msg")) {
      setError("Mesaj adı 'msg' ile başlamalıdır!");
    } else {
      setError("");
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-800">Mesaj Ekleme</h2>
        <button
          onClick={() => {
            setMessageName("");
            setMessageContent("");
            setFormId("");
            setFormSearchTerm("");
            setSqlOutput("");
            setError("");
          }}
          className="text-sm text-gray-600 hover:text-gray-800 flex items-center gap-1"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
          Temizle
        </button>
      </div>

      <div className="grid gap-6 bg-white rounded-lg p-6 shadow-sm">
        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Mesaj Adı
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
            </span>
            <input
              type="text"
              value={messageName}
              onChange={handleMessageNameChange}
              className={`w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors ${
                error ? 'border-red-500' : ''
              }`}
              placeholder="msg ile başlayan bir mesaj adı giriniz"
            />
          </div>
          {error && (
            <p className="mt-1 text-sm text-red-600">{error}</p>
          )}
        </div>

        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Mesaj İçeriği
          </label>
          <div className="relative">
            <span className="absolute top-3 left-3 text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7" />
              </svg>
            </span>
            <textarea
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors resize-none h-32"
              placeholder="Mesaj içeriğini giriniz"
            />
          </div>
        </div>

        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Form Adı
          </label>
          <div className="flex space-x-2">
            <div className="relative flex-1" ref={dropdownRef}>
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
              </span>
              <input
                type="text"
                value={formSearchTerm}
                onChange={(e) => {
                  setFormSearchTerm(e.target.value);
                  setIsDropdownOpen(true);
                }}
                onFocus={() => setIsDropdownOpen(true)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                placeholder="Form adını arayın veya seçin"
              />
              {isDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-auto">
                  {filteredFormItems.map((item) => (
                    <button
                      key={item.id}
                      onClick={() => handleFormSelect(item)}
                      className="w-full px-4 py-2 text-left hover:bg-gray-100 flex justify-between items-center"
                    >
                      <span>{item.name}</span>
                      <span className="text-gray-500 text-sm">ID: {item.id}</span>
                    </button>
                  ))}
                </div>
              )}
            </div>
            <input
              type="text"
              value={formId}
              onChange={(e) => setFormId(e.target.value)}
              className="w-32 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
              placeholder="Form ID"
            />
          </div>
        </div>

        <div className="flex gap-3">
          <button
            onClick={handleSubmit}
            disabled={!!error}
            className="flex-1 bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2 font-medium disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
            SQL Oluştur
          </button>
        </div>

        {sqlOutput && (
          <div className="mt-4 bg-gray-50 rounded-lg border border-gray-200 overflow-hidden">
            <div className="flex justify-between items-center px-4 py-2 bg-gray-100 border-b border-gray-200">
              <label className="text-sm font-medium text-gray-700">
                SQL Sorgusu
              </label>
              <button
                onClick={() => navigator.clipboard.writeText(sqlOutput)}
                className="flex items-center gap-1 px-3 py-1 text-sm bg-white text-gray-600 rounded-md hover:bg-gray-50 transition-colors border border-gray-300"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                Kopyala
              </button>
            </div>
            <pre className="p-4 text-sm text-gray-700 whitespace-pre overflow-auto max-h-96">
              {sqlOutput}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
} 