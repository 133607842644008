import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faHome } from '@fortawesome/free-solid-svg-icons';
import './style.css';
import { AuthContext } from '../../../context/AuthContext';
import { useContext } from 'react';

function Header() {
  const navigate = useNavigate();
  const {logout} = useContext(AuthContext)
  const handleLogout = () => {
    // Add your logout logic here
    logout();
    navigate('/');
  };

  const goToDashboard = () => {
    navigate('/Dashboard');
  };

  return (
    <header className="app-header">
      <div className="header-container">
        <div className="welcome-section">
          <h1 className="welcome-text">Hoşgeldin Alper</h1>
          <button onClick={goToDashboard} className="home-button" title="Ana Sayfa">
            <FontAwesomeIcon icon={faHome} className="home-icon" />
          </button>
        </div>
        <button onClick={handleLogout} className="logout-button">
          <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
          <span>Çıkış Yap</span>
        </button>
      </div>
    </header>
  );
}

export default Header;