import { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlay, faStop, faClock } from '@fortawesome/free-solid-svg-icons';
import '../styles/private-layout.css';

type DataType = {
  id: number;
  currency_pair: string;
  last: string;
  lowest_ask: string;
  highest_bid: string;
  change_percentage: string;
  change_utc0: string;
  change_utc8: string;
  base_volume: string;
  quote_volume: string;
  high_24h: string;
  low_24h: string;
  etf_net_value: string;
  etf_pre_net_value: string;
  etf_pre_timestamp: string;
  etf_leverage: string;
};

function CryptoDetection() {
  const [initialData, setInitialData] = useState<DataType[]>([]);
  const [cryptos, setCryptos] = useState<DataType[]>([]);
  const [interval, setInterVal] = useState(1);
  const [isStarted, setisStarted] = useState(false);
  const [intervalIds, setIntervalIds] = useState<NodeJS.Timeout | null>(null);
  const [firstVolumes, setFirstVolumes] = useState<any[]>([]);
  const [lastVolumes, setLastVolumes] = useState<any[]>([]);
  const [firstVolumeDate, setFirstVolumeDate] = useState<any>(null);
  const [topTen, setTopTen] = useState<any[]>([]);
  const [option, setOption] = useState<any>(5);
  const [search, setSearch] = useState<any>('');
  const [bigger, setBigger] = useState<any>('');

  function formatValue(volume: any): any {
    if (parseFloat(volume) >= 1_000_000_000) {
      return (parseFloat(volume) / 1_000_000_000).toFixed(2) + "B"; // Milyar
    } else if (parseFloat(volume) >= 1_000_000) {
      return (parseFloat(volume) / 1_000_000).toFixed(2) + "M"; // Milyon
    } else if (volume >= 1_000) {
      return (parseFloat(volume) / 1_000).toFixed(2) + "K"; // Bin
    } else {
      return parseFloat(volume).toFixed(2); // Bin altı
    }
  }

  function deFormatValue(volume: string): any {
    if (volume.endsWith('B')) {
      return (parseFloat(volume) * 1_000_000_000);
    } else if (volume.endsWith('M')) {
      return (parseFloat(volume) * 1_000_000);
    } else if (volume.endsWith('K')) {
      return (parseFloat(volume) * 1_000) // Bin
    } else {
      return parseFloat(volume) // Bin altı
    }
  }

  const startSearch = (value: any) => {
    var searchedLastV = cryptos.filter((item: DataType) => item.currency_pair.match(new RegExp(value.toUpperCase(), 'i')));
    var searchedFirstV = firstVolumes.filter((item: DataType) => item.currency_pair.match(new RegExp(value.toUpperCase(), 'i')));
    var searchedCryptos = cryptos.filter((item: DataType) => item.currency_pair.match(new RegExp(value.toUpperCase(), 'i')));
    setSearch(value);
    setCryptos([...searchedCryptos]);
    setFirstVolumes([...searchedFirstV]);
    setLastVolumes([...searchedLastV]);
  }

  useEffect(() => {
    if (isStarted) {
      startTracing(true);
    }
  }, [interval]);

  const requestGateİo = async () => {
    const requestOptions: any = {
      method: "GET"
    };
    fetch("https://api.gateio.ws/api/v4/spot/tickers", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let gelenData = JSON.parse(result);

        gelenData = gelenData.filter((item: DataType) => item.currency_pair.match(/([A-Z])\w+USDT/));
        gelenData.sort((a: DataType, b: DataType) => (a.currency_pair > b.currency_pair) ? 1 : -1);

        gelenData = gelenData.map((item: DataType, index: number) => {
          var yuzdeFarkı = initialData.find((i: DataType) => i.currency_pair == item.currency_pair)?.change_percentage;

          return {
            id: index,
            currency_pair: item.currency_pair,
            last: item.last,
            lowest_ask: item.lowest_ask,
            highest_bid: item.highest_bid,
            change_percentage: item.change_percentage,
            first_change_percentage: yuzdeFarkı,
            change_utc0: item.change_utc0,
            change_utc8: item.change_utc8,
            base_volume: item.base_volume, // Formatlı
            quote_volume: formatValue(item.quote_volume), // Formatlıitem.quote_volume, // Formatlı
            high_24h: item.high_24h,
            low_24h: item.low_24h,
            etf_net_value: item.etf_net_value,
            etf_pre_net_value: item.etf_pre_net_value,
            etf_pre_timestamp: item.etf_pre_timestamp,
            etf_leverage: item.etf_leverage,
            is_growing: (parseFloat(item.change_percentage) > parseFloat(yuzdeFarkı == null ? '0' : yuzdeFarkı)) ? 1 : 0
          }

        });

        if (firstVolumes.length === 0) {
          setFirstVolumes(gelenData);
        } else {

          var lVolume = [];

          for (let index = 0; index < firstVolumes.length; index++) {
            lVolume.push({
              id: index,
              volume: ((gelenData[index].quote_volume) - (firstVolumes[index].quote_volume)),
              name: firstVolumes[index].currency_pair,
              change_percentage: firstVolumes[index].change_percentage
            });
          }

          setLastVolumes(lVolume);
          getBigger(lVolume);
          setTopTen(lVolume.filter((item) => item.change_percentage > 0 && item.volume > 0)
            .sort((a, b) => {
              const changePercentageA = parseFloat(a.change_percentage);
              const changePercentageB = parseFloat(b.change_percentage);
              return changePercentageB - changePercentageA;
            })
            .slice(0, option));
        }
        setCryptos([]);
        setCryptos(gelenData);

        if (initialData.length == 0) {
          setInitialData(gelenData);
        }
      })
      .catch((error) => console.error(error));
  }

  const startTracing = async (onlyListAndSort: boolean) => {
    if (onlyListAndSort) {
      requestGateİo();
      setFirstVolumeDate(new Date().toLocaleString());
      return;
    }

    setisStarted(true);

    if (intervalIds === null) {
      const newIntervalId = setInterval(() => {
        requestGateİo();
      }, interval * 1000);
      setIntervalIds(newIntervalId);
    }
  };

  const stopTracing = () => {
    if (intervalIds !== null) {
      clearInterval(intervalIds);
      setIntervalIds(null);
      setisStarted(false);
    }
  };

  const getBigger = (list: any[] = lastVolumes) => {
    let biggerChange = list.filter((item) => item.change_percentage > 0 && item.volume > 0);
    let big = biggerChange.sort((a, b) => {
      return (b.volume - a.volume)
    });
    setBigger(big[0].name + ' ' + big[0].volume + ' ' + big[0].change_percentage);
  }

  return (
    <div className="crypto-detection">
      <div className="crypto-header">
        <h1 className="crypto-title">Kripto Takip</h1>
        <div className="crypto-controls">
          <div className="flex items-center gap-4">
            <div className="relative">
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              />
              <input
                type="text"
                placeholder="Kripto ara..."
                value={search}
                onChange={(e) => startSearch(e.target.value)}
                className="crypto-search pl-10"
              />
            </div>
            <select
              value={interval}
              onChange={(e) => setInterVal(parseInt(e.target.value))}
              className="crypto-search"
            >
              <option value="1">1 Saniye</option>
              <option value="2">2 Saniye</option>
              <option value="3">3 Saniye</option>
            </select>
            <button
              onClick={() => startTracing(!isStarted)}
              className={`crypto-button ${isStarted ? 'bg-red-500' : ''}`}
            >
              <FontAwesomeIcon icon={isStarted ? faStop : faPlay} className="mr-2" />
              {isStarted ? 'Durdur' : 'Başlat'}
            </button>
          </div>
        </div>
      </div>

      <div className="crypto-grid">
        <DataGrid
          rows={cryptos}
          columns={[
            { field: 'currency_pair', headerName: 'Sembol', width: 130 },
            { field: 'last', headerName: 'Son Fiyat', width: 130 },
            { field: 'change_percentage', headerName: 'Değişim %', width: 130 },
            { field: 'base_volume', headerName: 'Hacim', width: 130 },
            { field: 'high_24h', headerName: '24s Yüksek', width: 130 },
            { field: 'low_24h', headerName: '24s Düşük', width: 130 }
          ]}
          slots={{
            toolbar: GridToolbar
          }}
          autoHeight
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 }
            }
          }}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
          className="bg-white rounded-lg shadow-sm"
        />
      </div>

      {firstVolumeDate && (
        <div className="mt-4 p-4 bg-blue-50 rounded-lg flex items-center gap-2">
          <FontAwesomeIcon icon={faClock} className="text-blue-500" />
          <span className="text-sm text-blue-600">
            İlk Hacim Tarihi: {new Date(firstVolumeDate).toLocaleString()}
          </span>
        </div>
      )}
    </div>
  );
}

export default CryptoDetection;
