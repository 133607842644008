import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Dashboard from './Dashboard'
import Projects from './Projects'
import Header from '../../components/layout-components/header'
import Footer from '../../components/layout-components/footer'
import CryptoDetection from './CryptoDetection'
import BizHelper from './bizmedHelper/page'
import './styles/private-layout.css'

function PrivateLayout() {
 
  return (
    <div className="private-layout">
      <Header/>
      <main className="private-layout-main">
        <Routes>
          <Route path='/Dashboard' element={<Dashboard />}></Route>
          <Route path='/Projects' element={<Projects />}></Route>
          <Route path='/CryptoDetection' element={<CryptoDetection />}></Route>
          <Route path='/bizHelper' element={<BizHelper />}></Route>
        </Routes>
      </main>
      <Footer/>
    </div>
  )
}

export default PrivateLayout