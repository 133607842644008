import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram, faChartLine, faTools } from '@fortawesome/free-solid-svg-icons';
import '../styles/private-layout.css';

function Dashboard() {
  const menuItems = [
    {
      title: 'Projeler',
      path: '/Projects',
      icon: faProjectDiagram,
      description: 'Tüm projelerinizi görüntüleyin ve yönetin'
    },
    {
      title: 'Kripto Takip',
      path: '/CryptoDetection',
      icon: faChartLine,
      description: 'Kripto para piyasasını anlık takip edin'
    },
    {
      title: 'BizHELPER',
      path: '/bizHelper',
      icon: faTools,
      description: 'İş süreçlerinizi kolaylaştıran araçlar'
    }
  ];

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <h1 className="dashboard-title">Kontrol Paneli</h1>
        <p className="text-gray-600">Hoş geldiniz! Tüm araçlarınıza buradan erişebilirsiniz.</p>
      </div>
      
      <div className="dashboard-grid">
        {menuItems.map((item) => (
          <Link to={item.path} className="dashboard-card" key={item.path}>
            <div className="dashboard-card-title">
              <FontAwesomeIcon icon={item.icon} className="mr-2" />
              {item.title}
            </div>
            <p className="dashboard-card-content">{item.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;